

















import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class ToS1 extends Vue {
}
