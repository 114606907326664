





















import { Component, Vue } from 'vue-property-decorator'
import ToS1 from '@/components/ToS1.vue'
import ToS2 from '@/components/ToS2.vue'
import { InvestorPlatformClient } from '@/client'
import { REQUIRED_TOS_KEY } from '@/constants'

@Component({
  components: {
    tos1: ToS1,
    tos2: ToS2
  }
})
export default class ToS extends Vue {
  tosVersion = -1
  isLoading = false

  mounted() {
    this.tosVersion = Number(this.$route.params['tosVersion'])
    console.log(this.tosVersion)
  }

  async acceptTos() {
    const client = InvestorPlatformClient.getFromStoredToken(process.env.VUE_APP_API_ENDPOINT!)
    this.isLoading = true
    try {
      await client.acceptTos(this.tosVersion)
      
      let requiredTos = localStorage.getItem(REQUIRED_TOS_KEY)
      if (requiredTos !== null && Number(requiredTos) == this.tosVersion) {
        localStorage.removeItem(REQUIRED_TOS_KEY)
        requiredTos = null
      }

      if (requiredTos === null) {
        await this.$router.push('/app')
      } else {
        await this.$router.push(`/tos/${requiredTos}`)
      }
    } finally {
      this.isLoading = false
    }
  }
}
